@import '../../styles/colors';
@import '../../styles/dimen';

.search-page .am-page__content {
  max-width: $max-page-width;
}

.search {
  margin-bottom: 1rem;
  position: relative;
}

.search__input {
  padding-left: 3.125rem;
}

.search__icon {
  left: .625rem;
  position: absolute;
  top: .6rem;
}

.advanced-search__filters__on {
  background-color: $white-hover;
  color: $dark-text;
  font-size: .875rem;
  padding: .4rem;
  position: absolute;
  right: 1rem;
  top: .35rem;

  .fa-plus {
    margin-right: .3rem;
  }
}

.search-header-info {
  font-family: 'Helvetica';
  font-style: italic;
  margin-bottom: 1.5rem;
  margin-top: 1.3125rem;
}

.search-input {
  padding-left: 3.125rem;
}

.results-list {
  position: relative;
}

.previous-next-button, .previous-next-button:hover {
  background-color: transparent;
  font-family: 'Roboto Regular';
}

.previous-next-button:disabled, .previous-next-button.disabled {
  background-color: $white !important;
  color: $black;
}

.previous-next-button__active {
  font-family: 'Roboto Bold';
}

.search-result-header {
  margin-bottom: 0;
  margin-top: 1.7rem;
  text-transform: uppercase;
  
  a {
    color: $black;
  }

  .select-search {
    color: $black;
    display: inline-block;
    font-family: 'Helvetica';
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.375rem;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.search-badge {
  background-color: $white-hover;
  color: $black;
  font-family: 'Helvetica';
  height: 1.4rem;
  line-height: 1rem;
  margin-bottom: .25rem;
  margin-left: 0;
  margin-right: .5rem;
}

.search-badge--title {
  background-color: $gray-text;
  color: $white;
}

.search-badge--date {
  background-color: $light-blue;
  color: $white;
}

.search__buttons {
  align-items: center;
  display: flex;
  padding: 1rem .8rem 1.5rem;

  :first-child {
    flex-grow: 1;
  }

  & > .btn {
    margin-left: .3rem;
    min-width: 5.5rem;
  }
}

.results-list--dimmed:after {
  background: rgba(255, 255, 255, .5);
  bottom: -1px;
  content: ' ';
  display: block;
  left: 0;
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 201;
}

.search-spinner-flex {
  align-items: center;
  display: flex;
  justify-content: center;

  .searching-spinner {
    font-family: 'Helvetica';
    font-size: 1.375rem;
    font-style: oblique;
    line-height: 1.4rem;
    margin-left: 1rem;
  }
}

.above-search {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .saved-searches {
    font-family: 'Helvetica';
    font-size: .875rem;

    .saved-search-applied {
      padding-right: 1.5rem;
    }
  }

  .search-header-info {
    font-family: 'Helvetica';
    font-style: italic;
    margin-bottom: 1.5rem;
    margin-top: 1.3125rem;
  }
}

.below-search {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .advanced-search__toggle {
    font-size: .875rem;

    .fa-chevron-down {
      transition: 300ms;
    }
  }

  .advanced-search__toggle--open .fa-chevron-down {
    transform: rotate(-180deg);
  }
}
