@import '../styles/colors';
@import '../styles/dimen';

.translate_dropdown_container .translate__dropdown__menu {
  @media (max-width: #{$mobile-width}) {
    column-count: 1;
  }
  @media (min-width: #{$mobile-width}) {
    column-count: 2;
  }
  @media (min-width: 768px) {
    column-count: 4;
  }
  @media (min-width: 1168px) {
    column-count: 6;
  }

  // Safari specific styling
  @media (min-width: 768px) {
    body.safari & > * {
      display: inline-block;
      width: 100%;
    }
  }
}

.translate__dropdown {
  display: inline-block;
}

.translate__mobile {
  padding: 0;
  position: relative;
}

.translate__menu, .translate__mobile {
  .dropdown-toggle.dropdown-item:after {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.translate__select {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Target devices of mobile-width. */
@media (max-width: #{$mobile-width}) {

  .translate__small {
    display: block;

    .translate__menu {
      display: block;
    }
  }
}

.translate__disclaimer {
  color: $gray-text;
  font-size: .875rem;
  padding: .25rem 1.5rem;
}

// Ensure dropdown menu does not overflow
.translate_dropdown_container .dropdown-menu.show {
  display: block;
  max-height: 100dvh; // Limit max height to viewport height
  overflow-y: auto; // Enable vertical scrolling
}

@media (max-width: #{$mobile-width}) {
  .translate_dropdown_container .dropdown-menu.show {
    max-width: 100%;
  }
}
