.codenav {
  display: flex;
  height: 100%;
  width: 100%;
}

.codenav__breadcrumbs {
  align-items: center;
  display: flex;
  padding-left: .5rem;
  z-index: $z-index_codenav__breadcrumbs;

  @media (max-width: #{$mobile-width}) {
    padding: 0;
  }

  .codenav_breadcrumbs_nav {
    flex-grow: 1;
    overflow: hidden;
  }

  .breadcrumb {
    flex-wrap: nowrap;
    white-space: nowrap;

    .breadcrumb-item {

      &:not(:first-child) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(2), &:nth-child(3) {
        max-width: 175px;
      }
    }
  }

  .code-options {
    text-align: right;
    white-space: nowrap;
  }
}

.codenav__left {
  background: #f5f5f5;
  height: calc(100vh - #{$top-nav-height} - #{$bottom-nav-height});
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties,unit-no-unknown */
  height: calc(100dvh - #{$top-nav-height} - #{$bottom-nav-height});
  position: relative;
  transition-duration: .3s;
  transition-property: width;
  width: $left-nav-width;
  z-index: $z-index_codenav__left;

  @media (max-width: #{$mobile-width}) {
    bottom: 0;
    height: calc(100vh - #{$top-nav-height-mobile} + 1px);
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties,unit-no-unknown */
    height: calc(100dvh - #{$top-nav-height-mobile} + 1px);
    overflow-x: hidden;
    position: fixed;
    top: calc(#{$top-nav-height-mobile} - 1px);
    width: 100%;
  }
}

// Toggled means left nav isclosed for desktop; open for mobile
.codenav__left--closed {
  width: $left-nav-closed;

  @media (max-width: #{$mobile-width}) {
    width: 0;
  }
}

.codenav__left--preview {
  height: calc(100vh - #{$top-nav-height} - #{$breadcrumb-height} - #{$bottom-nav-height});

  @media (max-width: #{$mobile-width}) {
    height: calc(100vh - #{$bottom-nav-height});
  }
}

.codenav__left-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: $left-nav-width;
  position: absolute;
  right: 0;
  width: 100%;
}

.codenav__nav-toggle {
  background-color: $dark-text;
  border: none;
  border-radius: 50%;
  color: $white;
  height: 1.5rem;
  margin-top: .5rem;
  padding: .1rem .2rem;
  position: absolute;
  right: -.75rem;
  width: 1.5rem;
  z-index: $z-index_codenav__toggle;

  &:hover, &:focus {
    background-color: $dark-text-hover;
    color: $white;
    cursor: pointer;
  }

  @media (max-width: #{$mobile-width}) {
    display: none;
  }
}

.codenav__versions {
  margin-left: .5rem;
  margin-right: 1rem;
  margin-top: .5rem;
}

.codenav__toc {
  flex-grow: 1;
  margin: .5rem;
  margin-top: .5rem;
  overflow-y: auto;

  @media (max-width: #{$mobile-width}) {
    min-width: 95vw;
  }
}

.codenav__toc--code-preview {
  margin-top: 0;
}

.codenav__right {
  flex-grow: 1;
  transition-duration: .3s;
  transition-property: width;
  width: calc(100% - #{$left-nav-width});

  @media (max-width: #{$mobile-width}) {
    width: 0;
  }
}

// Toggled means left nav isclosed for desktop; open for mobile
.codenav__right--closed {
  width: calc(100% - #{$left-nav-closed});

  @media (max-width: #{$mobile-width}) {
    width: 100%;
  }
}

.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
}

.codenav__section {
  position: relative;

  mark, .mark {
    cursor: pointer;
  }
}

.codenav__section--preview {
  height: calc(100vh - #{$top-nav-height} - #{$breadcrumb-height} - #{$bottom-nav-height});

  @media (max-width: #{$mobile-width}) {
    height: calc(100vh - #{$top-nav-height-mobile} - #{$breadcrumb-height} - #{$bottom-nav-height});
  }

  .codenav__section-body {
    // accounting for preview-header height (40px)
    height: calc(100vh
    - #{$top-nav-height}
    - #{$breadcrumb-height}
    - #{$preview-header-height}
    - #{$bottom-nav-height});

    @media (max-width: #{$mobile-width}) {
      height: calc(100vh
      - #{$top-nav-height-mobile}
      - #{$breadcrumb-height}
      - #{$preview-header-height}
      - #{$bottom-nav-height});
    }
  }
}

.codenav__section-body {
  // accounting for codenav__breadcrumbs height (40px)
  height: calc(100vh - #{$top-nav-height} - #{$breadcrumb-height} - #{$bottom-nav-height});
  overflow-y: auto;
  padding: 1rem;
  transition: padding .15s ease-in-out;

  @media (max-width: #{$mobile-width}) {
    height: calc(100vh - #{$top-nav-height-mobile} - #{$breadcrumb-height} - #{$bottom-nav-height});
  }
}

.codenav__section-body--annotations {
  padding-left: 3rem;

  .rbox:hover::after {
    background: rgba(155, 155, 155, .2);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.codenav__section-body--header {
  // accounting for archive-header height (2rem)
  height: calc(100vh
  - #{$top-nav-height}
  - #{$breadcrumb-height}
  - #{$dark-header-height}
  - #{$bottom-nav-height});

  @media (max-width: #{$mobile-width}) {
    height: calc(100vh
    - #{$top-nav-height-mobile}
    - #{$breadcrumb-height}
    - #{$dark-header-height}
    - #{$bottom-nav-height});
  }
}

.codenav__section-body--pdf {
  overflow: hidden;
  padding: 0;
}

.highlighter-viewer {
  border: none;
  height: 100%;
  width: 100%;
}

.archive .dark-header {
  align-items: center;
  display: flex;
  font-size: .875rem;
  height: $dark-header-height;
  justify-content: center;
  padding: 0 1rem;
}

.back-to-search .dark-header {
  align-items: center;
  display: flex;
  height: $dark-header-height;
}

.rbox {
  position: relative;
}

.toc-entry {
  .collapse, .collapsing {
    margin-left: 1.5rem;
  }
}

.toc-entry--code {
  padding-right: .5rem;
}

.toc-entry .dropdown-toggle {
  white-space: nowrap;
}

.toc-entry__wrap {
  border-radius: .125rem;
  display: flex;
  padding-left: .5rem;
  transition: background-color .15s ease-in-out;
}

.toc-entry__wrap--is-viewed {
  background-color: rgba(187, 188, 186, .8);
}

.toc-entry__loading {
  align-items: center;
  color: $dark-text;
  display: flex;
  font-family: 'Roboto Italic';

  .spinner-border {
    border: .1rem solid currentColor;
    border-radius: 50%;
    border-right-color: transparent;
    height: 1.25rem;
    margin-right: .5rem;
    width: 1.25rem;
  }
}

.toc-entry--has-children:not(.toc-entry--code) {
  .collapse, .collapsing {
    margin-left: .75rem;
  }
}

.toc-link {
  border-radius: .125rem;
  color: $dark-text;
  cursor: pointer;
  flex-grow: 1;
  font-size: .875rem;
  padding: .5rem .25rem;
  transition: background-color .15s ease-in-out;
}

.toc-link--is-viewed {
  background-color: rgba(187, 188, 186, .8);
}

.toc-entry__wrap--unavailable .toc-link {
  cursor: not-allowed;
}

.toc-caret {
  color: $dark-text;
  cursor: pointer;
  font-size: 1.25rem;
  margin-left: -.5rem;
  padding: .25rem .75rem;
}

.toc-caret.dropdown-toggle::after {
  transform: rotate(-90deg);
}

.toc-caret.dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(0deg);
}

.toc-caret.dropdown-toggle::after {
  transition: 300ms;
}

.single-version-dropdown {
  background-color: $white !important;
  text-align: left;
}

.version-header {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  & > * {
    vertical-align: middle;
  }
}

.multi-version-dropdown {
  padding-right: 3rem !important;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.code-footer {
  padding-top: 1rem;
}

.code-footer p {
  color: $dark-text;
  font-size: .625rem;
  white-space: pre-wrap;
}

.codenav__bottom {
  background-color: $dark-text;
  bottom: 0;
  box-shadow: 0 -.0625rem .1875rem 0 rgba(0,0,0,.2);
  display: flex;
  height: $bottom-nav-height;
  position: fixed;
  width: 100%;
}

.codenav__bottom-left {
  color: $white !important;
  font-size: 1.125rem !important;
  position: relative;
  transition-duration: .3s;
  transition-property: width;
  width: $left-nav-width;

  @media(max-width: $mobile-width) {
    display: none;
  }

  a {
    color: $white;
  }
}

.codenav__bottom-left--closed {
  width: 0;
}

.codenav__bottom-left-inner {
  min-width: $left-nav-width;
  padding: .375rem;
  position: absolute;
  right: 0;
}

.codenav__bottom-right {
  background: #f5f5f5;
  color: $black;
  display: flex;
  flex-grow: 1;
  font-size: .875rem;
  padding: .375rem;

  @media(max-width: $mobile-width) {
    width: 100%;
  }

  a,
  .btn,
  .btn:hover:not(:disabled):not(.disabled),
  .btn:not(:disabled):not(.disabled):active {
    align-items: center;
    color: $black;
    display: flex;
  }

  .disabled-link {
    color: $input-grey;
    font-style: italic;
  }
}

.bottom-right__doc {
  align-items: center;
  display: flex;
  flex-grow: 1;

  .disabled-link {
    align-items: center;
    display: inline-flex;
  }
}

.bottom-right__match {
  align-items: center;
  display: flex;
  margin: 0 .5rem;
}

.bottom-right--left {
  justify-content: flex-start;

  & > * {
    text-align: left;
  }
}

.bottom-right--right {
  justify-content: flex-end;

  & > * {
    text-align: right;
  }
}
