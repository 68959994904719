.form-control {
  border-color: $input-grey;
  border-radius: 2px;
  font-size: .75rem;

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    border-color: $dark-text;
    box-shadow: none;

    &.dropdown-toggle::before {
      background-color: $dark-text;
      transition: background-color .15s ease-in-out;
    }
  }

  &.placeholder {
    color: $input-grey;
  }
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-control:invalid, 
.form-control.is-invalid {
  background-image: none;
}

.form-group label:not(.form-check-label):not(.custom-control-label) {
  font-weight: bold;
}

label {
  margin: 0;
}

.am-file-input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}

.form-check {
  padding-left: 0;
}

.form-check-input {
  left: 1.1rem;
  margin-left: .3rem;
  opacity: 0;
  position: relative;

  & + label {
    cursor: pointer;
    padding-left: .625rem;
    user-select: none;
  }

  // Box.
  & + label::before {
    color: $input-grey;
    content: '\ea53';
    display: inline-block;
    font: normal 18px/1 'icomoon';
    height: 18px;
    left: -9px;
    position: relative;
    vertical-align: text-top;
  }

  &:focus + label {
    outline: 1px dotted;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: 5px auto -webkit-focus-ring-color;
  }

  // Disabled state label.
  &:disabled + label {
    color: $input-grey;
    cursor: not-allowed;

    div {
      color: $input-grey;
    }
  }

  // Checkmark. Could be replaced with an image
  &:checked + label {
    color: $dark-text;

    &::before {
      color: $btn-primary;
      content: '\ea52';
    }

    &.check--partial::before {
      color: $input-grey;
    }
  }

  &:active:not(:disabled):not(.disabled) + label::before {
    color: $light-blue;
    content: '\ea52';
  }
}

.custom-control-input {
  &:focus,
  &:focus:not(:checked) {
    & ~ .custom-control-label {
      outline: 1px dotted;
      /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
      outline: 5px auto -webkit-focus-ring-color;
    }
    & ~ .custom-control-label::before {
      border-color: $input-grey;
      box-shadow: none;
    }
  }

  &:checked ~ .custom-control-label::before {
    background-color: $btn-primary;
    border-color: $btn-primary;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    background-color: $light-blue;
    border-color: $light-blue;
  }
}

.custom-switch {
  padding-left: $switch-width;

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX($switch-width - 1.8rem);
  }
}

.custom-switch .custom-control-label {
  cursor: pointer;
  user-select: none;

  // track
  &::before {
    background-color: $input-grey;
    border: none;
    left: .1rem - $switch-width;
    margin-top: -.1rem;
    top: .3rem;
    width: $switch-width - .8rem;

    &:focus {
      box-shadow: none;
    }
  }

  // knob
  &::after {
    background-color: $white;
    border-radius: 1.25rem;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
    height: 1.25rem;
    left: -$switch-width;
    top: .05rem;
    width: 1.25rem;
  }
}

.search-close {
  position: absolute;
  right: 10px;
  top: 6px;
}
