@import '../../styles/dimen.scss';
@import '../../styles/z_index';

.codenav__section-body .code-options {
  // Override these to avoid inheriting them from toc styles
  background: transparent !important;
  border: none !important;
  float: right;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  position: relative;
  z-index: $z-index_codenav__options;
}

.code-options {
  .btn-white-circle, .btn-primary-circle,
  .btn-white-kebab, .btn-primary-kebab {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-left: .5rem;

    & > [role='img'] {
      margin-right: 0;
    }
  }
}

.code-options__kebab {
  display: none;
}
.code-options__buttons {
  display: block;
}

/* Target devices of mobile-width. */
@media (max-width: #{$mobile-width}) {
  .code-options__kebab {
    display: block;
  }
  .code-options__buttons {
    display: none;
  }
}
