@import './colors';

html, body {
  /* Removes higlight on tap on mobile devices. */
  -webkit-tap-highlight-color: transparent;
}

body {
  /* Disables "double tap to zoom in" on mobile devices. */
  /* https://stackoverflow.com/questions/46167604/iphone-html-disable-double-tap-to-zoom */
  touch-action: manipulation;
}

body {
  font-size: .875rem;
  margin: 0;
  overflow-y: auto;
}

a {
  color: $light-blue;
  font-size: .875rem;
  text-decoration: underline;

  &:active,
  &:hover {
    color: $btn-primary;
  }

  .svg-inline--fa {
    margin-right: .3125rem;
  }
}

*:not(.img) {
  & > del {
    background-color: $del;
    padding: .1rem .3rem;
  }

  & > ins {
    background-color: $ins;
    margin-left: .2rem;
    padding: .1rem .3rem;
    text-decoration: none;
  }
}

.img > ins {
  display: none;
}

mark, .mark {
  background-color: $mark;
  color: $dark-text;
  padding: .1rem;
}

.mark--selected {
  background-color: $mark-selected;
}

.disabled, .btn:disabled {
  color: $dark-text-hover;
}

.disabled-link {
  pointer-events: none;
}

/* Internet Explorer adds borders around all images */
img {
  border: none;
}

section {
  padding: .5rem 1rem;
}

@for $index from 1 through 6 {
  h#{$index} {
    font-weight: bold;
  }
}

h1 {
  background-color: #f3f3f7;
  font-size: 1.5rem;
  padding: .5rem 1rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: .75rem;
}

p {
  font-size: .875rem;
}

.roboto {
  font-family: 'Roboto Regular';
  font-size: 1rem;
}

.roboto--italic {
  font-family: 'Roboto Italic';
  font-size: 1rem;
}

.secondary-header {
  background-color: $light-blue;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.3);
  color: $white;
  font-family: 'Roboto Regular';
  font-weight: normal;
  padding: .5rem 1rem;
  transition: background-color .15s ease-in-out;
}

svg {
  transition: color .15s ease-in-out;
}

.dark-header {
  background-color: $dark-text;
  color: $white;
  font-family: 'Roboto Regular';
  font-weight: normal;
  margin-bottom: 0;
  padding: .5rem 1rem;
}

.preview, .archive {
  .dark-header {
    text-align: center;
  }
}

.am-page {
  display: flex;
  justify-content: center;
  padding: .5rem 1rem 1rem;
}

.am-page--scroll {
  height: calc(100vh - #{$top-nav-height} - #{$breadcrumb-height});
  overflow: auto;
  overflow-y: scroll;
  position: relative;
  width: 100%;

  .am-page__content {
    margin: 0;
  }
}

.am-page__content {
  background-color: $white-hover;
  border: .0625rem solid $light-gray;
  box-shadow: 0 0 .375rem 0 rgba(0,0,0,.3);
  flex-grow: 1;
  margin-top: 2.5rem;
  max-width: 41.25rem;
  transition: margin-top .15s ease-in-out;
  width: 100%;

  @media (max-width: 1199px) {
    margin-top: .25rem;
  }
}

.am-page__content--nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.am-page__header {
  background-color: $white;
  border-radius: .125rem .125rem 0 0;
  box-shadow: 0 .0625rem .375rem 0 rgba(0,0,0,.3);
  color: #222222;
  font-family: 'Helvetica';
  font-size: 1.5rem;
  font-weight: bold;
  line-height: .3125rem;
  padding: $page-heading-padding;
  text-align: center;
  white-space: nowrap;

  .dropdown-toggle.form-control::after {
    top: 1.1875rem;
  }

  .dropdown-toggle.form-control::before {
    top: .1875rem;
  }

  .nav-tabs, a {
    font-weight: normal;
  }

  .nav-tabs .nav-item {
    margin-bottom: -.1875rem;
  }

  .tab-pane.active {
    padding: $pane-padding;
  }
}

.am-page__pane {
  padding: $pane-padding;
  position: relative;
}

.am-page__bottom {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: $pane-padding;

  & > *:not(:last-child) {
    margin-right: .3rem;
  }

  .btn {
    min-width: 5.5rem;
  }
}

.tabs-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: -$page-heading-padding;
  padding-top: $page-heading-padding;
}

.tabs-wrapper .nav-tabs {
  flex-grow: 1;
  margin: 0;
}

.list-group-item {
  border-radius: 2px;

  &:last-child,
  &:first-child,
  &:hover:not(.list-item--selected),
  &:focus:not(.list-item--selected) {
    border-radius: 2px;
  }

  &:hover:not(.list-item--selected),
  &:focus:not(.list-item--selected) {
    background-color: $white-hover;
    cursor: pointer;
    z-index: 0;
  }

  &.list-item--selected {
    background-color: rgba(83,130,194,.1);
    border: 1px solid $light-blue;
    z-index: 1;
  }
}

.breadcrumb {
  font-family: 'Roboto Regular';
  padding: .5rem 1rem;

  .breadcrumb-item, a, ::before {
    color: #fff;
    font-size: 1.125rem;
    line-height: 24px;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
    content: '>';
  }
}

// Unfortunately need important because ckeditor loads styles
// after these as runtime
.ck-editor {
  margin-bottom: 1rem !important;
}

.ck.ck-editor__editable_inline>:first-child,
.ck-content .image {
  margin-top: 0 !important;
}

.ck-content:not(.ck-editor__editable) figcaption {
  display: none;
}

.ck-content ,
.ck.ck-editor__main > .ck-content {
  padding: 1rem;
  padding-bottom: .25rem;
}

.ck.ck-editor__main > .ck-editor__editable.ck-focused {
  border-color: $dark-text !important;
  box-shadow: none !important;
  transition: border-color .15s ease-in-out;
}

.no-data {
  font-style: italic;
  margin: 0;
  padding: $page-heading-padding 10%;
  text-align: center;
}

.version-icon {
  height: 1rem;
  margin-right: .3125rem;
  width: 1rem;
}

.react-datepicker-wrapper {
  display: block;
}

.tooltip-inner {
  max-width: 170px;
}

.grecaptcha-badge:not(.show) {
  visibility: hidden;
}
