@import '../../styles/colors';

.color-input-row {
  align-items: center;
  display: flex;
  margin-bottom: 1.25rem;
  
  & > * {
    margin-right: 1rem;
  }
}

.color-swatch-button {
  border: 1px solid $input-grey;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.color-hex {
  width: 75px;
}

.color-swatch-cover {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.twitter-picker {
  left: .6rem;
  top: 5rem;
}

.h3-padding {
  margin-top: 2.5rem;
}

.preview-toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.exclude_disclaimer {
  margin-bottom: 1rem;
}
