.currency-info {
  font-size: .875rem;
  padding: 1rem 1rem 0;
}

.overview-buttons {
  padding: 1rem;

  & > * {
    margin-right: 1rem;
  }
}

.overview-footer {
  padding: 0 1rem;
}
