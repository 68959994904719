@import '../../styles/colors';

.rdw-editor-toolbar, .rdw-editor-main {
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  margin-top: .3125rem;
  padding: 0 1.25rem;
}

.img-upload {
  display: flex;
  margin-bottom: 1.25rem;
}

.img-upload__wrapper {
  border: 2px dashed $input-grey;
  color: $input-grey;
  cursor: pointer;
  display: inline-block;
  font-size: .875rem;
  font-style: italic;
  height: 64px;
  min-width: 250px;
  text-align: center;
  user-select: none;
  width: 250px;

  &:focus {
    border-color: $dark-text;
    outline: none;
  }

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  & > * {
    vertical-align: middle;
  }
}

.img-upload__wrapper--active {
  border-color: $dark-text;
}

.img-upload__wrapper img {
  max-height: 100%;
  max-width: 100%;
  padding: .625rem 0;
}

.img-upload__actions {
  flex-grow: 1;
  margin-left: 1.25rem;

  & > .btn {
    margin-right: 1rem;
  }
}
