@import '../../styles/colors';
@import '../../styles/dimen';

.popover--annotation {
  background: $white-hover;
  max-width: unset;
  padding: 0;
  width: 325px;

  .btn-seconday, .btn-primary, .btn-danger {
    min-width: 4rem;
  }

  @media (max-width: #{325px}) {
    min-width: calc(100% - .5rem);
    width: unset;
  }
}

.bs-popover-bottom:not(.popover--empty) .arrow::after {
  border-bottom-color: $white-hover;
}

.popover--annotation .annotation-drawer__bottom {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.popover--annotation.popover--empty .annotation-drawer__bottom {
  border-radius: .3rem;
}

.annotation-drawer {
  left: -2rem;
  position: absolute;
}

.annotation-drawer__loading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.annotation-drawer__list {
  max-height: 300px;
  overflow: auto;
}

.annotation-drawer__bottom {
  background: $white;
  padding: 1rem;
}

.annotation-drawer__btn {
  color: $btn-primary !important;
}

.annotation {
  padding: 1rem 1rem 0;

  p, .form-control, .text-center {
    font-size: .75rem;
  }

  p {
    white-space: pre-wrap;
  }
}

.annotation:not(:first-child) {
  padding-top: 1rem;
  position: relative;

  &:before {
    border-bottom: 2px solid $divider-grey;
    content: '';
    display: block;
    left: 1rem;
    position: absolute;
    right: 1rem;
    top: 0;
  }
}

.annotation__header {
  align-items: center;
  display: flex;
  margin-bottom: .5rem;
}

.annotation__author {
  flex-grow: 1;
  font-family: 'Roboto Bold';
  font-size: .875rem;
}

.annotation__date {
  color: $input-grey;
  font-family: 'Roboto Regular';
  font-size: .75rem;
  margin-right: .5rem;
}

.annotation__buttons {
  display: flex;

  .btn-link {
    color: $input-grey;
    line-height: 0;
  }
}

.annotation__edit-buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}

.annotation__delete-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.annotation__edit-buttons, .annotation__delete-buttons {
  & > :not(:last-child) {
    margin-right: .25rem;
  }
}
