@import '../styles/colors';
@import '../styles/dimen';

/* Stretches the page to 100% height */
.webpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}

.webpage--loading {
  /*
  `react-waypoint` wouldn't work correctly with `display: none` -> `display: block`.
  https://github.com/brigade/react-waypoint/issues/164#issuecomment-299640438
  display: none;
  */
  visibility: hidden;
}

/* Content takes all free space */
.webpage__content {
  flex: 1 0 auto;
}

.webpage__header {
  margin-top: calc(var(--unit) * 2);
  padding-bottom: calc(var(--unit) * 2);
  padding-top: calc(var(--unit) * 2);
}

.react-website__loading {
  background-color: rgba(0, 0, 0, .1);
}
.react-website__loading--initial {
  background-color: #fff;
}

.react-website__loading--initial .react-website__loading-spinner {
  color: rgba(0, 0, 0, .25);
}

#google_translate_element2 {
  display: none;
}

.top-nav {
  align-items: center;
  display: flex;
  margin-top: -.0625rem;
  padding: 0 1.5625rem;
  transition: background-color .15s ease-in-out;

  @media(max-width: $mobile-width) {
    padding: 0 0 0 .5rem;
  }
}

a.skip-main {
  height: 1px;
  left: -999px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
  z-index: -999;
}

a.skip-main:focus, a.skip-main:active {
  background-color: $light-blue;
  border: 4px solid $black;
  border-radius: 15px;
  color: $white;
  font-size: 1.2em;
  height: auto;
  left: auto;
  margin: 10px;
  overflow: auto;
  padding: 5px;
  text-align: center;
  top: auto;
  width: 30%;
  z-index: 999;
}

// Referenced https://jonsuh.com/hamburgers/
.hamburger {
  border: 0;
  cursor: pointer;
  display: none;
  height: $top-nav-height-mobile;
  overflow: visible;
  padding: 0;
  text-transform: none;
  transition-duration: .15s;
  transition-property: opacity,filter;
  transition-timing-function: linear;

  @media(max-width: $mobile-width) {
    display: inline-block;
  }
}

.hamburger__box {
  display: inline-block;
  height: $hamburger-height;
  margin: calc((#{$top-nav-height-mobile} - #{$hamburger-height}) / 2);
  position: relative;
  width: $hamburger-width;
}

.hamburger__inner,
.hamburger__inner:after,
.hamburger__inner:before {
  background-color: $dark-text;
  border-radius: 3px;
  height: 3px;
  position: absolute;
  transition-duration: .15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: $hamburger-width;
}
.hamburger__inner:before {
  top: 7px;
}

.hamburger__inner:after {
  bottom: -7px;
  top: 14px;
}

.hamburger__inner:after,
.hamburger__inner:before {
  content: '';
  display: block;
}

.hamburger--active {
  .hamburger__inner {
    transform: translate3d(0, 7px, 0) rotate(45deg);
  }
  .hamburger__inner:before {
    opacity: 0;
    transform: rotate(-45deg) translate3d(-5px, -5px, 0);
  }
  .hamburger__inner:after {
    transform: translate3d(0,-14px,0) rotate(-90deg);
  }
}

.logo-link {
  display: inline-block;
  height: #{$top-nav-height};

  @media (max-width: #{$mobile-width}) {
    height: #{$top-nav-height-mobile};
  }
}

.header-logo {
  max-height: 100%;
  max-width: 100%;
  padding: .3rem 0;

  @media (max-width: #{$mobile-width}) {
    padding: 0;
  }
}

.top-nav__left {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.top-nav__right {
  align-items: center;
  display: flex;

  & > * {
    display: inline-block;
    margin-left: .25rem;
  }

  a {
    &:focus, &:hover {
      text-decoration: underline;
    }
  }

  .btn-secondary {
    font-size: 1rem;

    &:hover {
      background: rgba(0, 0, 0, .1) !important;
    }

    &:focus:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled):active {
      background-color: inherit;
      box-shadow: none;
    }
  }

  .show > .dropdown-toggle:not(.form-control):not(:disabled):not(.disabled) {
    background: rgba(0, 0, 0, .2);
  }
}

.bookmarks__select {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.my-account-small {
  display: none;
}
.my-account-large {
  display: inline-block;
}

.bookmark-menu, .bookmark-mobile {
  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.bookmark-menu .dropdown-menu {
  max-width: 300px;
}

.bookmark-mobile {
  padding: 0;
  position: relative;
}

/* Target devices of mobile-width. */
@media (max-width: #{$mobile-width}) {
  .my-account-small {
    display: block;

    &.bookmark-mobile {
      display: none;
    }

    &.bookmark-menu {
      display: block;
    }
  }
  .my-account-large {
    display: none;
  }

  .webpage--mobile .my-account-small {
    &.bookmark-mobile {
      display: block;
    }

    &.bookmark-menu {
      display: none;
    }
  }
}

.dropdown-toggle.dropdown-item {
  font-size: 1rem;
  margin-left: 0;

  &:active:not(:disabled):not(.disabled),
  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    background-color: $white-hover;
    box-shadow: none !important;
  }

  &[aria-expanded='false']:focus:not(:disabled):not(.disabled):not(:hover) {
    background-color: transparent;
  }
}

.header-links {
  a {
    margin-left: .625rem;
  }
}

.bookmark__menu {
  margin: 0;
  padding-left: .8rem;
}

.bookmark-menu, .bookmark-mobile {
  .dropdown-toggle.dropdown-item:after {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.btn-white {
  background: #fff;
}

.btn-secondary-kebab {
  & > [role='img'] {
    margin-right: 0;
    vertical-align: -.1rem;
  }
}

.top-nav__kebab {
  display: none;
}

.my-account-small {
  display: none;
}

.my-account-large {
  display: block;
}

/* Target devices of mobile-width. */
@media (max-width: #{$mobile-width}) {
  .my-account-small {
    display: block;
  }
  .my-account-large {
    display: none;
  }
  .top-nav__kebab {
    display: block;
  }
  .top-nav__right {
    display: none;
  }
}

.body-fixed {
  position: fixed;
  width: 100%;
}
