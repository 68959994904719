.codenav__dropdown {
  align-items: center;
  display: flex;

  .codenav__compare-btn {
    display: inline-block;
    text-align: center;
    width: 3rem;

    .spinner-border {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
}

.codenav__compare {
  align-items: center;
  font-size: .75rem;
  margin-top: .5rem;

  & > :first-child {
    margin-right: .5rem;
    width: 4.5rem;
  }
  & > :last-child {
    display: inline-block;
    width: calc(100% - 5rem);
  }
}

.codenav__dropdown .version-dropdown {
  width: 100%;

  .dropdown-menu {
    max-height: calc(100vh - 225px);
    overflow-y: auto;
  }

  &.version-dropdown--multiple {
    display: inline-block;
    width: calc(100% - 3rem);
  }

  .form-control {
    text-align: left;
  }
}
