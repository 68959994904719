@import '../../styles/colors';

#location-selection .dropdown-menu {
  overflow: hidden;
}

#location-selection .client-items {
  max-height: 150px;
  overflow: auto;
  padding-bottom: .5rem;
}

#location-selection .client-refine {
  color: $btn-primary;

  &:hover {
    color: $btn-primary-hover;
  }
}

#location-selection .form-group {
  position: relative;
}

#location-selection .form-control {
  padding-right: 2rem;
}

.region-header {
  font-size: 1rem;
  font-weight: bold;
}

.client-region-list__client {
  padding-left: 3.5rem;
}

.location-selection__dropdown {
  &.dropdown-toggle {
    align-items: center;
    color: $black;
    display: inline-flex;
    font-size: .75rem;
  }
  
  .location-selection__results-toggle {
    flex-grow: 1;
  }
  
  .remove-all-locations {
    &.fa-times {
      font-size: 1.5rem;
      padding-right: .4rem;
    }
  }
}

.location-badges {
  overflow-x: auto;
  padding-bottom: 1.25rem;
  white-space: nowrap;
}

.location-selection__badges {
  max-height: 8.5rem;
  padding: 0 1rem;

  .btn {
    border: none;
    padding: 0;

    .fa-times-circle, .fa-pencil-alt {
      color: $white;
      cursor: pointer;
    }

    .fa-times-circle {
      margin-left: .3125rem;
    }
  }

  .fa-plus, .fa-minus, .fa-pencil-alt, .fa-times-circle {
    margin-right: 0 .3125rem;
  }
  
  .badge {
    margin-left: 0;
    margin-right: .625rem;
    padding: 0;
    padding-left: .3125rem;
  }

  .partial-badges .badge {
    background-color: $partial-yellow;
    color: $dark-text;

    .fa-times-circle, .fa-pencil-alt {
      color: $dark-text;
    }
  }

  .excluded-badges .badge {
    background-color: $btn-danger;
    text-decoration: line-through;
  }
}
