.search__toggle {
  padding-right: 3rem !important;
}

.search__dropdown {
  border: 1px solid;
  box-shadow: none;
  height: 12.5rem;
  padding: 0;
}

.search-items {
  height: 8rem;
  overflow-y: scroll;
  padding-bottom: .5rem;
}

.state-search-close {
  position: absolute;
  right: 1.875rem;
  top: 1.375rem;
}