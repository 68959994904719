.client-dropdown-container {
  display: flex;
}

#client-dropdown {
  flex-grow: 1;
  margin-right: 1rem;
}

#client-dropdown .client-items {
  max-height: 250px;
  overflow: auto;
  padding-bottom: .5rem;
}

#client-dropdown .dropdown-header {
  color: #000000;

  &:not(:first-child) {
    margin-top: .75rem;
  }
}

#client-dropdown .dropdown-item {
  padding: .15rem 1.5rem;
}

#client-dropdown .dropdown-menu {
  padding: 0;
  right: 0;
}

#client-dropdown .form-group {
  position: relative;
}

#client-dropdown .form-control {
  padding-right: 2rem;
}
