@import '../styles/colors';

.table--edit .table-item {
  align-items: center;
  display: flex;
  font-family: 'Helvetica';
  font-size: .875rem;
  justify-content: space-between;

  .edit-delete-buttons {
    border-top: none;
    justify-content: flex-end;
    
    .btn-link {
      color: $black;
      padding: 0 .5rem;
    }
  }
  .form-group {
    margin-bottom: 0;

    .form-control {
      font-size: .875rem;
    }
  }
  .title-input {
    border-top: none;
    line-height: 1.75rem;
    
    .form-control {
      height: 1.75rem;
    }
  }
}
