@import '../styles/colors.scss';

.ingest-problems {
  max-height: 300px;
  overflow-y: auto;
}

.problem {
  border: 1px solid transparent;
  border-radius: 2px;

  &:not(:first-child) {
    margin-top: 5px;
  }

  &:focus-within {
    border: 1px solid $dark-text;
    border-radius: 2px;
  }
}

.problem--open {
  border: 1px solid $dark-text;

  .toggle-arrow {
    transform: rotate(180deg);
  }
}

.problem-toggle {
  display: flex;
  text-align: left;
  width: 100%;

  &:not(:disabled):not(.disabled) {
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }
}

.file-path {
  font-weight: bold;
}

.problem-info {
  margin: 0;

  pre {
    margin: 0;
    white-space: pre-wrap;
  }
}

.toggle-text {
  flex-grow: 1;
}

.toggle-arrow {
  height: 20px;
  transition: 300ms;
}

.problem-instance {
  padding: .5rem;

  &:nth-child(odd) {
    background-color: $white;
  }
}
