@import '../../styles/dimen';

.account-page .am-page__content {
  max-width: $max-page-width;
}

.following-buttons {
  text-align: right;
  width: 100px;
}
