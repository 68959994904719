.sitemap {
  .sitemap--content {
    display: flex;
    justify-content: space-around;
    padding: 2rem;

    .sitemap__bookmarks {
      max-width: 40%;
    }

    .sitemap__btn-link {
      border: 0;
      padding: 0;
    }
  }
}
