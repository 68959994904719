@import '../../styles/colors';

.minutes--dimmed:after {
  background: rgba(255, 255, 255, .5);
  bottom: 0;
  content: ' ';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
  z-index: 201;
}

.drop-overlay {
  bottom: 0;
  color: $dark-text;
  left: 0;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 202;
  
  .drop-overlay__inner {
    align-items: center;
    border: 2px dashed $dark-text;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

.minutes:focus {
  outline: none;
}

.table--minutes {
  font-size: .875rem;

  th:last-child, td:last-child {
    border-right: 1px solid $input-grey;
    min-width: 200px;
    text-align: center;
    width: 200px;
  }

  .table {
    th:last-child, td:last-child {
      border-right: none;
    }
  }

  .btn-link {
    color: $black;
    font-size: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .btn-link-create {
    color: $light-blue;
    font-size: .875rem;
    padding: 0 .2rem .2rem;
  }
}