@import '../styles/colors';

.scope-input {
  background: $white;
  font-family: 'Roboto Regular';
  height: 225px;
  overflow-y: scroll;
}

.toc-entry__check {
  padding-top: 4px;
  position: relative;

  .form-check-input {
    position: absolute;
  }
}

.btn-toggle {
  background: none;
  border: none;
}
