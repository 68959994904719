.btn {
  border-radius: 2px;
  font-size: .875rem;

  &:not(.btn-sm)
  :not(.btn-lg)
  :not(.roboto)
  :not(.roboto-italic)
  :not(.btn-link)
  :not(.btn-primary-circle)
  :not(.btn-white-circle) {
    padding: .4rem 1.5rem;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:active {
    box-shadow: inset 0 2px 2px 0 rgba(0,0,0,.2) !important;
  }

  &:not(.btn-link):disabled,
  &:not(.btn-link).disabled {
    background-color: $btn-disabled;
    border: 1px solid transparent;

    &:hover {
      background-color: $btn-disabled;
    }
  }
}

@mixin btn($type, $bgcolor, $bgcolor-hover, $btn-color:$white) {
  background-color: $bgcolor;
  border-color: transparent;
  color: $btn-color;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: $bgcolor-hover;
    border-color: transparent;
    box-shadow: none;
    color: $btn-color;
  }

  .show > &.dropdown-toggle {
    @extend .btn-#{$type}, :hover;
    border-color: #333 !important;

    &:focus, &:active {
      box-shadow: none;
    }
  }
}

@mixin circle-btn($background, $color) {
  border: 1px solid $background;
  border-radius: 50%;
  color: $background;
  height: 1.75rem;
  margin: .3rem .6rem;
  padding: .1rem .3rem;
  position: relative;
  width: 1.75rem;

  &:hover:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled):active {
    background-color: $background;
    border-color: $background;
    box-shadow: none !important;
    color: $color;
  }
  &::before {
    bottom: -.6rem;
    content: '';
    left: -.6rem;
    position: absolute;
    right: -.6rem;
    top: -.6rem;
  }
}

.btn-lg {
  font-size: 1rem;
}

.btn-secondary {
  @include btn('secondary', transparent, $white-hover, $dark-text);
}

.btn-primary {
  @include btn('primary', $btn-primary, $btn-primary-hover);
}

.btn-info {
  @include btn('info', $light-blue, $light-blue-hover);
}

.btn-warning {
  @include btn('warning', $partial-yellow, $yellow-hover, $dark-text);
}

.btn-success {
  @include btn('success', $btn-success, $btn-success-hover);
}

.btn-danger {
  @include btn('danger', $btn-danger, $btn-danger-hover);
}

.btn-link {
  color: $light-blue;
  padding: 0;
  padding-left: .1875rem;
  text-decoration: underline;

  &:disabled, &.disabled {
    background: transparent;
    border: 1px solid transparent;
  }

  &:hover:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    box-shadow: none !important;
    color: $btn-primary;
  }
}

.btn-export {
  color: $dark-text;
  padding: .3rem;

  &:hover:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled):active {
    background: $export-grey;
    box-shadow: none !important;
  }
}

.btn-primary-circle {
  @include circle-btn($btn-primary, $white);
}

.btn-white-circle {
  @include circle-btn($white, $light-blue);
}

.btn-primary-kebab {
  @include circle-btn($btn-primary, $white);

  border-color: transparent;

  &:hover:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
  }

  &[aria-expanded='true'] {
    background-color: $btn-primary !important;
    color: $white !important;
  }
}

.btn-white-kebab {
  @include circle-btn($white, $light-blue);

  border-color: transparent;

  &:hover:not(:disabled):not(.disabled) {
    border-color: transparent;
  }

  &:not(:disabled):not(.disabled):active
  &:not(:disabled):not(.disabled):focus {
    background-color: transparent;
    border-color: transparent;
  }

  &[aria-expanded='true'] {
    background-color: $white !important;
  }
}

.btn-secondary-kebab {
  @include circle-btn($btn-primary, $dark-text);

  border-color: transparent;

  &:hover:not(:disabled):not(.disabled) {
    background-color: rgba(0, 0, 0, .1) !important;
    border-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus {
    background-color: transparent;
    border-color: transparent;
  }

  &[aria-expanded='true'] {
    background-color: rgba(0, 0, 0, .1) !important;
  }
}

.btn--not-allowed:not(:disabled):not(.disabled) {
  cursor: not-allowed;
}

.btn--wait,
.btn--loading:not(:disabled):not(.disabled) {
  cursor: wait;
}

.btn [role='img'] {
  margin-right: .3125rem;
}

.dropdown-toggle.btn,
.dropdown-toggle.form-control {
  padding-right: 1.5625rem;
  text-align: left;

  &::after {
    position: absolute;
    right: 10px;
    top: 17px;
  }
}

.dropdown-toggle.form-control {
  display: inline-block;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &::before {
    background-color: $input-grey;
    bottom: 0;
    content: '';
    position: absolute;
    right: 35px;
    top: 0;
    width: 1px;
  }

  &::after {
    font-size: 1rem;
    right: 13px;
  }

  .svg-inline--fa {
    margin-right: .3125rem;
  }
}

.dropdown-item {
  cursor: pointer;

  &:hover, &:active {
    background-color: $white-hover;
  }

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    box-shadow: none;
    color: $dark-text;
  }

  .svg-inline--fa {
    margin-right: .5rem;
  }
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  box-shadow: -2px 3px 6px 1px rgba(0,0,0,.3);
  margin: 0;
}

.dropdown-search {
  margin: 1rem;
}

.nav-tabs {
  border-bottom: none;
}

.nav-item:not(:last-child) {
  margin-right: 1.5rem;
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 4px solid transparent;
  padding: .25rem .75rem;

  &.active {
    border-bottom: 4px solid $btn-primary;
    color: inherit;
  }

  &:not(.active):hover {
    border-bottom: 4px solid $input-grey;
  }
}

.react-tabs__tab-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.react-tabs__tab {
  cursor: pointer;
  font-size: .875rem;
  margin-right: 1.5rem;
  padding: .25rem .75rem;

  &:not(.react-tabs__tab--selected):hover {
    border-bottom: 4px solid $input-grey;
  }
}

.react-tabs__tab--selected {
  border-bottom: 4px solid $btn-primary;
}

a.nav-link {
  color: $dark-text;
}

.badge {
  font-family: 'Roboto Regular';
  margin-left: .625rem;
}

.badge-secondary {
  background-color: $light-blue;
}

.button-link {
  text-decoration: underline;
}
