@import '../../styles/colors';
@import '../../styles/dimen.scss';

.advanced-filter__header {
  align-items: center;
  display: flex;
  font-family: 'Helvetica';
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  .syntax-help {
    color: $btn-link;
    font-size: .75rem;
    text-decoration: underline;

    .fa-external-link-alt {
      margin-right: .2rem;
    }
  }
}

.advanced-search__hr {
  margin: 0 auto;
}

.advanced-form {
  background-color: $white-hover;
  padding: 1.5rem;

  .advanced-form__labels {
    font-family: 'Helvetica';
    font-size: .625rem;
  }
}

.advanced-search .dropdown-menu {
  width: 100%;
}

.advanced-form__query-filters {
  margin-right: 0;
  padding-left: 1.5rem;
  padding-top: .75rem;
}
  
.advanced-form__query-filters__header {
  font-size: .875rem;
  font-weight: bold;
  height: 1rem;
  margin: .5rem 0;
  width: 100%;
}

.advanced-form__dropdowns {
  padding-top: .75rem;
}

.advanced-form__checkbox {
  margin-top: 1rem;
}

.advanced-search__checkboxes {
  padding-top: .3rem;
}

.manage-search {
  float: right;
  margin-top: .5rem;
  padding-right: 0;
}

.advanced-search__left__user > .location-search {
  margin-top: 3.5rem;
}

@media (max-width: #{$mobile-width}) {
  .advanced-search__left {
    margin-bottom: 1rem;
  }
}
