
$white-hover: #f3f3f7;
$dark-text: #333;
$dark-text-hover: #444;
$gray-text: #757575;
$input-grey: #959595;
$export-grey: #ccc;
$divider-grey: #bfbebe;
$partial-yellow: #ffc107;
$yellow-hover: #ffd861;
$white: #fff;
$black: #000;
$light-blue: #4275bd;
$light-blue-hover: #2d538a;
$light-gray: #bbbcba;
$table-border-grey: #dfdfdf;
$del: #ffaaaa;
$ins: #aaffaa;
$mark: #ffff89;
$mark-selected: #e87d35;

$btn-success: #3e923d;
$btn-success-hover: #43a047;
$btn-primary: #253a57;
$btn-primary-hover: #2f496d;
$btn-danger: #cc4949;
$btn-danger-hover: #d85d5d;
$btn-link: #253a57;
$btn-link-hover: #000;
$btn-link-disabled: #757575;
$btn-disabled: #bfbebe;
