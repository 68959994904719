.popover {
  border: none;
  box-shadow: 0 3px 10px 1px rgba(0,0,0,.3);
  padding: 1rem;
}

.popover-header {
  align-items: flex-start;
  background: transparent;
  display: flex;
  padding: 0;
  padding-bottom: 1rem;
}

h3.popover-header {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.popover-header .close {
  float: right;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.popover-body {
  padding: 0;
}

.popover-body:not(.popover-body--drawer) {
  max-height: 300px;
  overflow: auto;
}

.popover-footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}
.popover-footer > :not(:last-child) {
  margin-right: .25rem;
}

.close {
  color: $input-grey;
  opacity: 1;

  &:not(:disabled):not(.disabled):not(:active):hover {
    color: $black;
  }

  &:active {
    color: $input-grey;
  }
}

.modal-dialog {
  margin: 6rem auto;
  max-width: 600px;
}

.modal-header {
  background-color: $white;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
  display: block;
}

.modal-header .close {
  color: $white-hover;
  font-size: 2rem;
  opacity: .5;
  position: absolute;
  right: -2rem;
  text-shadow: none;
  top: -2rem;

  &:not(:disabled):not(.disabled):not(:active):hover {
    color: $white-hover;
    opacity: .8;
  }
}

.modal-title {
  font-size: 1.5rem;
  text-align: center;
}

.modal-content {
  background-color: $white-hover;
  border-radius: 2px;
}

.modal-body {
  min-height: 200px;
  overflow: hidden;
  padding: 2rem;
}

.modal-footer {
  border: none;
}

.modal-footer,
.popover-footer,
.annotation__delete-buttons,
.annotation__edit-buttons {
  .spinner-border {
    border: .2rem solid currentColor;
    border-radius: 50%;
    border-right-color: transparent;
    height: 1rem;
    margin-right: .5rem;
    width: 1rem;
  }
}
