@import '../../styles/colors';

.delete-client {
  & > * {
    margin: 0;
    vertical-align: middle;
  }
}

.table--drafts, .table--versions {
  font-size: .875rem;

  th:last-child, td:last-child {
    border-right: 1px solid $input-grey;
    min-width: 200px;
    text-align: center;
    width: 200px;
  }

  .table {
    th:last-child, td:last-child {
      border-right: none;
    }
  }

  .btn-link {
    color: $black;
    font-size: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .btn-link-create {
    color: $light-blue;
    font-size: .875rem;
    padding: 0 .2rem .2rem;
  }
}

.table--versions {
  td:nth-child(2) {
    text-align: left;
    width: 100%;
  }

  .table {
    th:nth-child(2) {
      text-align: left;
      width: 100%;
    }

    th:nth-child(3), td:nth-child(3) {
      min-width: 150px;
      text-align: left;
    }
  }
}

.version-codes .table--reorder td {
  border: none;
  cursor: grab;
}

.table--reorder tbody {
  tr:nth-child(odd) {
    background-color: $white-hover;

    &:hover {
      background-color: rgba(0, 0, 0, .075);
    }
  }
}

.version-codes > td {
  border: none;
  padding: 0;

  .collapse.show, .collapsing.show {
    border-top: 1px solid #dee2e6;
  }
}

.tr--hidden, .list-item--hidden {
  color: $input-grey;
  font-style: italic;
}

.draft-progress {
  & > * {
    vertical-align: middle;
  }
}

.td--imported {
  color: $input-grey;
  font-size: .75rem;
  font-weight: bold;
  text-align: right;
}

.version-code-line {
  background: $white-hover;
  height: 8px;
}

.version-arrow {
  transition: 300ms;
}

.version-arrow--expanded {
  transform: rotate(90deg);
}

.draft-actions, .version-actions {
  text-align: right;
}