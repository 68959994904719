.am-page__bottom--login > :first-child {
  flex-grow: 1;
}

.auth__message {
  margin: 1.25rem;
  text-align: center;
}

.auth__link {
  margin: .3125rem;
}

.btn-signup {
  margin-left: 2rem;
  padding: .4rem 1.5rem;
}
