@font-face {
  font-family: 'Roboto Regular';
  font-size: .875rem;
  src: url('../../assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Italic';
  src: url('../../assets/fonts/RobotoCondensed-Italic.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('../../assets/fonts/RobotoCondensed-Bold.ttf');
}

@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.ttf');
}