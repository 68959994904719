.table {
  background-color: $white;
  border: 1px solid $input-grey;
  border-radius: 2px;
  margin: 1.875rem 0;
  position: relative;

  &:focus {
    outline: none;
  }

  th, td {
    padding: .5rem;
    vertical-align: middle;

    &.handle {
      min-width: 30px;
      padding-left: .5rem;
      padding-right: 0;
      width: 30px;
    }
  }

  .table {
    border: none;
    margin: 0;
  }
}

.table thead th {
  border-color: $input-grey;
  border-width: 1px;
  vertical-align: middle;
}

.table-hover tbody tr.tr--no-data {
  text-align: center;
  
  &:hover {
    background-color: transparent;
  }

  p {
    margin: 2rem;
  }
}

.table--dimmed:after {
  background: rgba(255, 255, 255, .5);
  bottom: -1px;
  content: ' ';
  display: block;
  left: 0;
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 201;
}

.xsl-table {
  td, th {
    vertical-align: top;
  }

  tfoot {
    tr {
      height: 0;
    }

    td {
      height: 0;
      width: auto;
    }
  }
}

.xsl-table--percent {
  min-width: 700px;
  width: 100%;
}

.xsl-table--scroll {
  display: inline-block;
  max-height: 300px;
  max-width: 100%;
  overflow: auto;
  position: relative;
  z-index: 0;
}

.xsl-table--header {
  height: 0;
  overflow-y: visible;
  position: sticky;
  top: 0;
  z-index: 2;

  td, th {
    background: $white;
  }

  // Cover up top edge bleeding on chrome
  &::before {
    background: $white;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: -1px;
  }

  // Hide header on IE 10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
}

.xsl-table--body {
  position: relative;
  z-index: 1;
}
