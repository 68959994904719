@import '../styles/colors';
@import '../styles/z_index';

.exports {
  bottom: -3rem;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.3);
  font-family: 'Roboto Regular';
  opacity: 0;
  position: fixed;
  right: 1.5rem;
  transition-duration: 300ms;
  transition-property: opacity bottom;
  width: 350px;
  z-index: $z-index_exports;
}

.exports--visible {
  bottom: 1.5rem;
  opacity: 1;

  &.exports--bottombar {
    bottom: 4rem;
  }
}

.exports__tasks {
  background-color: $white;
}

.exports__header {
  align-items: center;
  background: $dark-text;
  color: $white;
  cursor: pointer;
  display: flex;

  &:hover {
    background: $dark-text-hover;
  }
}

.exports__preparing {
  flex-grow: 1;
  padding-left: 1rem;
}

.exports__arrow {
  height: 50px;
  transition: 300ms;
  width: 50px;
}

.exports__arrow--expanded {
  transform: rotate(180deg);
}

.exports__request {
  align-items: center;
  display: flex;
  margin: 0 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $table-border-grey;
  }
}

.request__delete {
  height: 50px;
  margin-left: -17px;
  width: 50px;
}

.request__middle {
  flex-grow: 1;
  height: 1.5rem;
  padding-right: 1rem;
  position: relative;

  & > * {
    bottom: 0;
    position: absolute;
    top: 0;
  }
}

.request__actions {
  text-align: center;
  width: 100px;

  .spinner-border {
    border: .2rem solid currentColor;
    border-radius: 50%;
    border-right-color: transparent;
    height: 1rem;
    margin-right: .5rem;
    width: 1rem;
  }
}

.request__open {
  font-size: .875rem;
}

.request__failed {
  color: $btn-danger;
  user-select: none;
}

.request__name {
  opacity: 1;
  transform: scale(1, 1);
  transition: 300ms;
  z-index: $z-index_exports + 10;
}

.request__name--hide {
  opacity: 0;
  transform: scale(.5, .5);
}

.request__progress {
  font-size: .625rem;

  .progress {
    border-radius: .4rem;
    height: .8rem;
    margin-bottom: 2px;
  }

  .progress .bg-info {
    background-color: $light-blue !important;
  }
}

.btn--remove {
  padding: 0 1.5rem;
}

.btn--remove {
  opacity: 0;
  pointer-events: none;
  transform: scale(.5, .5);
  transition: 300ms;
  z-index: $z-index_exports + 20;
}

.btn--remove-appear {
  opacity: 1;
  pointer-events: all;
  transform: scale(1, 1);
}
