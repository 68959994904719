.code-options-modal .modal-body {
  min-height: 280px;
  padding: 2rem;
}

.code-options-modal .modal-header {
  padding-bottom: 2.8125rem;
}

.code-options-modal .react-tabs__tab-list {
  justify-content: center;
  margin-top: -1.875rem;
}

.code-options-modal .svg-inline--fa {
  margin-right: .5rem;
}

.code-options-modal .fa-link {
  left: 10px;
  position: absolute;
  top: 12px;
}

.export-button {
  display: inline-block;
  margin: .3rem 0;
  text-align: center;
  width: 33.3%;

  .svg-inline--fa {
    font-size: 2.5rem;
    margin-bottom: .5rem;
  }
}
