$mobile-width: 1000px;

$bottom-nav-height:  40px;
$breadcrumb-height: 40px;
$preview-header-height: 40px;
$dark-header-height: 2rem;
$switch-width: 3rem;
$top-nav-height: 60px;
$hamburger-height: 18px;
$hamburger-width: 24px;
$pane-padding: 1.5rem;
$page-heading-padding: 1.875rem;
$max-page-width: 1140px;

$top-nav-height-mobile: 40px;
$left-nav-width: 325px;
$left-nav-closed: 1rem;
