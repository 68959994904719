@import '../../styles/colors';
@import '../../styles/dimen';

.browse-page .am-page__content {
  max-width: $max-page-width;
}

.browse-header-box {
  border-bottom: 2px solid #dfdfdf;
  box-sizing: border-box;
  font-size: 1.25rem;
  margin: 1.5rem 0;
  padding-bottom: 1.5rem;
  text-align: center;
}

.browse-header {
  margin: 1.5rem 0;
}

.browse-link {
  font-size: 1.25rem;
  margin: .3125rem 0;
}

.browse-columns {
  @media (max-width: 768px) {
    column-count: 1;
  }
  @media (min-width: 768px) {
    column-count: 2;
  }
  @media (min-width: #{$mobile-width}) {
    column-count: 3;
  }
}
